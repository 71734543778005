import * as React from 'react';
import { Autocomplete, FormHelperText, Grid, InputLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';

MAutoComplete2.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })),
  includeAll: PropTypes.bool,
  GridProps: PropTypes.object,
}

const ALL_OPTION = { id: "", label: 'All' };

export default function MAutoComplete2({ options = [], label, includeAll, sx, inputProps, GridProps = { xs: 12 }, onChange, value, variant, ...props }) {
  
  const [selectedLabel, setSelectedLabel] = React.useState(ALL_OPTION.label);
  const [selectedOption, setSelectedOption] = React.useState(ALL_OPTION);
  const items = React.useMemo(() => includeAll ? [ALL_OPTION, ...options] : options, [options, includeAll]);
  
  React.useEffect(() => {
    if (value && value !== selectedOption.id) {
      const item = items.find(o => o.id === value);
      setSelectedLabel(item?.label || ALL_OPTION.label);
      setSelectedOption(item || ALL_OPTION);
    }
  }, [value, items, selectedOption.id]);
    

  const handleChange = (event, newValue) => {
    setSelectedLabel(newValue);
    setSelectedOption(newValue);
    if (onChange) {
      onChange(newValue.id);
    }
  };

  return <Grid item {...GridProps} >
    <FormControl
      sx={{ width: '100%', mt: 1, }}
    >

      <InputLabel id={`select-label-${label}`}>{label}</InputLabel>
      <Autocomplete
        options={items}
        blurOnSelect
        value={selectedLabel}
        sx={{
          ...sx,
          backgroundColor: "#fff",
          borderRadius: 1,
        }}
        fullWidth
        renderInput={(params) => <TextField {...params} label={"Select Site"} variant="outlined" />}
        onChange={handleChange}
        required
        disableClearable
      />

      {props?.helperText && <FormHelperText>{props?.helperText}</FormHelperText>}
    </FormControl>
  </Grid>;


}