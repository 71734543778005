import React, { useContext, useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { LayoutContext } from '../../contexts';
const PAGE_SQUARE_HEIGHT = 260;

PageSquare.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    sx: PropTypes.object,
    gridX: PropTypes.number,
}

/**
 * Map props.children to Grid items for consistent props
 * @param {*} props 
 * @returns 
 */
function PageSquare({ gridX = 1, ...props }) {
    const { windowDimensions } = useContext(LayoutContext);
    const [child, setChild] = React.useState(props.children ? props.children : <>No item</>)

    useEffect(() => {

        const desktopProps = {
            component: Paper,
            variant: "outlined"
        };

        const {
            children,
            justifyContent,
            alignItems,
            title,
            sx,
            ...rest } = props;
        setChild(children || title
            ? <Grid
                item={true}
                container
                xs={12}
                md={6 * gridX > 12 ? 12 : 6 * gridX}
                lg={4 * gridX > 12 ? 12 : 4 * gridX}
                xl={3 * gridX > 12 ? 12 : 3 * gridX}
                {...rest}
                sx={{ minHeight: PAGE_SQUARE_HEIGHT }}
            >
                <Grid
                    direction={'column'}
                    {...(!windowDimensions.isMobile ? { ...desktopProps } : {})}
                    container
                    variant={"outlined"}
                    sx={{
                        pt: windowDimensions.isMobile ? 6 : 4,
                        width: 1,
                        height: 1,
                    }}
                    justifyContent={justifyContent}
                    alignItems={alignItems || 'center'}
                >
                    <Grid item>
                        {title && <Typography children={title} variant={"h4"} color="secondary" />}
                    </Grid>

                    <Grid item container
                        direction={'column'}
                        sx={{
                            p: 2.5,
                            ...sx,
                        }}
                    >

                        {children}
                    </Grid>
                </Grid>
            </Grid>
            : <>No item</>
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.children])

    return child;
}

export default PageSquare; 