/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routeConfig } from '../configs';
import { LayoutContext } from '../contexts';
/**
 * @params {{path: string, label: string}} tabs
 * @returns {[{
 *   parent_company_uuid: string;
 *   regional_company_uuid: string;
 *   business_unit_uuid: string;
 *   site_uuid: string;
 *   site_contact_uuid: string;
 *   business_unit_contact_uuid: string;
 *   supplier_uuid: string;
 *   tab_path: string;
 * }, (routeConfig: {fullPath?: string, path?: string}, params?: {
 *   parent_company_uuid?: string;
 *   regional_company_uuid?: string;
 *   business_unit_uuid?: string;
 *   site_uuid?: string;
 *   site_contact_uuid?: string;
 *   supplier_bill_uuid: string;
 *   supplier_uuid: string;
 * }) => void,
 * () => void,
 * {
 *      index: number;
 *      label: string;   
 *      tab: null;
 *      tabs: [];
 *      setIndex: (index: number) => void;
 *      
 * }
 * ]}
 */
export const useNavigateTo = (tabs = []) => {
    const params = useParams();
    const navigate = useNavigate();
    const layoutContext = useContext(LayoutContext);

    const navigateTo = (routeConfig, routeParams) => {
        if (typeof routeConfig === 'string') { // if only string, navigate to tab
            return goToTab(routeConfig, routeParams);
        }
        else if (typeof routeConfig === "number") {
            navigate(routeConfig);
            return;
        }
        let path = routeConfig?.fullPath || routeConfig?.path || "";
        const paramKeys = Object.keys(params);

        if (!path) {
            alert("Unable to navigate. Please check the provided route config to ensure it has a `path`.")
            return;
        }
        // replace route params first
        if (routeParams) {
            Object.keys(routeParams).forEach(paramKey => {
                path = path.replace(`:${paramKey}`, routeParams[paramKey]);
            })
        }
        // default to existing URL params if they are present
        if (paramKeys) {
            paramKeys.forEach(paramKey => {
                path = path.replace(`:${paramKey}`, params[paramKey]);
            })
        }
        navigate(path);
        layoutContext.scrollTo();
    };

    const [currentTab, setCurrentTab] = useState(tabs[0]);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);


    const goToTab = (tabPath, urlParams = {}) => {
        if (!tabs.length) {
            console.error("Cannot go to tab without Tabs")
            return;
        }

        const newTabIndex = tabs.findIndex((t) => t.path === tabPath);

        if (newTabIndex > -1) {
            const newTab = tabs[newTabIndex];
            setCurrentTab(newTab);
            setCurrentTabIndex(newTabIndex);
            navigateTo(routeConfig.tab_path,
                {
                    ...params,
                    tab_path: newTab.path,
                    ...urlParams,
                });
        } else {
            console.warn("Cannot go to tab " + tabPath);
        }
    };

    const setTabIndex = (newIndex) => {
        const newTab = tabs[newIndex];
        setCurrentTab(newTab);
        setCurrentTabIndex(newIndex);
        return newTab;
    }

    const handleTabChange = (event, newIndex = 0) => {
        if (!tabs.length) {
            console.error("Cannot go to tab without Tabs")
            return;
        }
        const newTab = setTabIndex(newIndex)
        navigateTo(routeConfig.tab_path,
            {
                ...params,
                tab_path: newTab.path,
            });
    };

    const hasTabPages = (pars) => {
        return Object.keys(pars).includes("tab_path")
    }


    useEffect(() => {
        if (!tabs.length) {
            return;
        }
        if (hasTabPages(params) && !params?.tab_path) {
            handleTabChange();
        }
    }, []);

    return [{ ...params, tab_path: params["*"] }, navigateTo, () => navigate(-1), { index: currentTabIndex, tab: currentTab, tabs, setIndex: setTabIndex, },];
};