/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import Page from '../../templates/Page';
import PageSection from '../../templates/PageSection';
import { routeConfig } from '../../../configs';
import { MButton, MDivider } from '../../@material-extend';
import { LookupContext, DeliveryTicketContext } from '../../../contexts';
import { useNavigateTo } from '../../../helpers';
import PageText from '../../templates/PageText';
import { dateHelper } from '../../../helpers/dateHelper';
import { permissions } from '../../../configs/permissionConstants';
import { confirmDialog } from '../../ConfirmDialog';
import { ButtonGroup } from '@mui/material';
import Restricted from '../../Restricted';

export default function DeliveryTicket() {

  const {
    activeEntity,
    setActiveEntity,
    deleteEntity
  } = useContext(DeliveryTicketContext);

  const [params, navigateTo, goBack] = useNavigateTo();
  const delivery_ticket_uuid = params.delivery_ticket_uuid;
  const lookup = useContext(LookupContext);

  const handleDeleteDeliveryTicket = async () => {
    await deleteEntity(activeEntity.delivery_ticket_uuid)
      .then(() => {
        navigateTo(routeConfig.delivery_ticket_table)
      })
  }

  useEffect(() => {
    setActiveEntity(delivery_ticket_uuid);
  }, []);

  return (
    <Page
      route={routeConfig.delivery_ticket_view}
      gap={1}
      onBack={goBack}
    >

      <PageSection
        title="Ticket Details"
        spacing={1}
        collapsible
        omitContainer
      >
        <PageText
          label="Jira Ticket Number"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.jira_ticket_number ? activeEntity.jira_ticket_number : "-"}
        />
        <PageText
          label="Summary"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.delivery_ticket_summary ? activeEntity.delivery_ticket_summary : "-"}
        />
        <PageText
          label="Site Name"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid ? lookup.byValue('site_hierarchy', activeEntity.site_uuid).label : ' - '}
        />
        <PageText
          label="Site UUID"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.site_uuid}
        />
        <PageText
          label="Ticket Created"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.delivery_ticket_created_at ? dateHelper.formatLocalDateTime(activeEntity.delivery_ticket_created_at) : "-"}
        />
        <PageText
          label="Ticket UUID"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.delivery_ticket_uuid}
        />
        <PageText
          label="Supplier"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.supplier_name ? activeEntity.supplier_name : "-"}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Status"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.delivery_ticket_status}
        />
        <PageText
          label="Resolution"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.issue_resolution ? activeEntity.issue_resolution : "-"}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />
        <PageText
          label="Requested Start"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.requested_delivery_window_start ? dateHelper.formatLocalDateTime(activeEntity.requested_delivery_window_start) : "-"}
        />
        <PageText
          label="Requested End"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.requested_delivery_window_end ? dateHelper.formatLocalDateTime(activeEntity.requested_delivery_window_end) : "-"}
        />
        <PageText
          label="Confirmed Start"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.confirmed_delivery_window_start ? dateHelper.formatLocalDateTime(activeEntity.confirmed_delivery_window_start) : "-"}
        />
        <PageText
          label="Confirmed End"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.confirmed_delivery_window_end ? dateHelper.formatLocalDateTime(activeEntity.confirmed_delivery_window_end) : "-"}
        />
        <MDivider
          variant='list-middle'
          direction='row'
        />

        <PageText
          label="Recognized By Seeq"
          GridProps={{ xs: 12, md: 6 }}
          body={(activeEntity?.delivery_recognized_by_seeq == null) ? "-" : activeEntity?.delivery_recognized_by_seeq ? "Yes":"No"}
        />
        <PageText
          label="Alternate Recognition Method"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.alternate_delivery_recognition_method ? activeEntity.alternate_delivery_recognition_method : "-"}
        />
        <PageText
          label="Delivery End (seeq)"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.delivery_end_date_seeq ? dateHelper.formatLocalDateTime(activeEntity.delivery_end_date_seeq) : "-"}
        />
        <PageText
          label="Delivery Quantity (seeq)"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.delivery_quantity_seeq ? activeEntity.delivery_quantity_seeq : "-"}
        />
        <PageText
          label="Split Load Type"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.split_load_type ? activeEntity.split_load_type : "-"}
        />
        <PageText
          label="Split Quantity"
          GridProps={{ xs: 12, md: 6 }}
          body={activeEntity?.split_quantity ? activeEntity.split_quantity : "-"}
        />
      </PageSection>

      <Restricted to={[permissions.DELETE_DELIVERY_TICKETS]}>
        <PageSection
          title=''
          label='Delete the delivery ticket?'
          endActions={<ButtonGroup sx={{ mr: 2 }}>
              <MButton
                size="small"
                tooltip="Delete selected delivery ticket"
                onClick={() => {
                  confirmDialog("Do you want to delete the delivery ticket? This is permanent.", () => handleDeleteDeliveryTicket())
                }}
                icon="delete"
                color="primary"
                children="Delete Delivery Ticket"
              />
          </ButtonGroup>}
          collapsible
        />
      </Restricted>


    </Page >
  );
}