/* eslint-disable react-hooks/exhaustive-deps */
import { Box, ButtonGroup, Grid } from '@mui/material';
import { useContext, useState } from 'react';
import Page from '../../templates/Page';
import PageSection from '../../templates/PageSection';
import { routeConfig } from '../../../configs';
import RefreshIcon from '@mui/icons-material/Refresh';
import Search from '@mui/icons-material/Search';
import { MAutoComplete, MButton, MDatePicker, MFormGroup } from '../../@material-extend';
import { LayoutContext, LookupContext, UsageEventContext } from '../../../contexts';
import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { dateHelper } from '../../../helpers/dateHelper';
import { useNavigateTo } from '../../../helpers';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckedIcon from '../../CheckedIcon';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';
import { useSearchParams } from 'react-router-dom';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Box sx={{ width: 20, }} />
      <GridToolbarExport
        csvOptions={{
          fileName: 'Usages',
          fields: ['site_uuid', 'usage_end_timestamp', 'usage_pulses',
            'quantity_required', 'usage_quantity',
            'usage_duration', 'run_count_id', 'billable_quantity',
            'manual_entry', 'manual_entry', 'testing_mode'
          ],
          utf8WithBom: true,
        }}
        printOptions={{
          disableToolbarButton: true
        }}
      />
      <Box sx={{ width: 100, }} />
    </GridToolbarContainer>
  );
}

export default function UsageEventTable() {
  const siteNameComparator = (id1, id2) => (lookup.byValue('site_hierarchy', id1).label).localeCompare(lookup.byValue('site_hierarchy', id2).label);
  const [searchParams, setSearchParams] = useSearchParams();
  const [, navigateTo] = useNavigateTo();
  const lookup = useContext(LookupContext);
  
  const {
    windowDimensions: { height },
    constants,
    setPageState,
    pageState,
    isLoading,
  } = useContext(LayoutContext);

  const {
    entities,
    queryHandler
  } = useContext(UsageEventContext);


  const options = React.useMemo(() => {
    if (!lookup?.values?.site_hierarchy.length) return []
    return lookup.values.site_hierarchy.map(site => ({ label: site.label, id: site.site_uuid }))
  }, [lookup.values.site_hierarchy])

  const [pageSize, setPageSize] = useState(100);

  /**
   * Memoized columns 
   */
  const columns = React.useMemo(() => [
    {
      field: "actions",
      disableColumnMenu: true,
      disableReorder: true,
      headerName: '',
      widthMultiplier: 3,

      renderCell: (params) => (<ButtonGroup
        color="inherit"
      >
        <MButton
          onClick={() => navigateTo(routeConfig.usage_view, { usage_event_uuid: params.id })}
          size="small"
          aria-label="view"
          children="View Usage"
          tooltip="View Usage Event"
        />
      </ButtonGroup>
      ),
    },
    {
      field: 'site_uuid',
      description: 'Site',
      headerName: 'Site',
      hide: false,
      widthMultiplier: 5,
      sortComparator: siteNameComparator,
      renderCell: (params) => {
        var site_name = lookup.byValue('site_hierarchy', params.value).label
        if (typeof site_name === "undefined") {
          site_name = 'Retired'
        }
        return (
          site_name
        )
      },
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return `${lookup.byValue('site_hierarchy', value).label}`;
      },
    },
    {
      field: 'usage_end_timestamp',
      description: 'Usage End Date',
      headerName: 'End Date',
      widthMultiplier: 4,
      renderCell: (params) => {
        return (
          dateHelper.formatLocalDateTime(params.value)
        )
      },
      valueFormatter: (value) => {
        if (value == null) {
          return '';
        }
        return `${dateHelper.formatLocalDateTime(value)}`;
      },
    },
    {
      field: 'usage_pulses',
      headerName: 'Pulses',
      widthMultiplier: 1.5,
    },
    {
      field: 'quantity_required',
      headerName: 'Requested',
      widthMultiplier: 1.8,
    },
    {
      field: 'usage_quantity',
      headerName: 'Delivered',
      widthMultiplier: 1.5,
    },
    {
      field: 'usage_duration',
      description: 'Usage Duration',
      headerName: 'Duration',
      widthMultiplier: 1.5,
    },
    {
      field: 'run_count_id',
      headerName: 'Run Count',
      widthMultiplier: 2.0,
    },
    {
      field: 'manual_entry',
      description: 'Manual Entry',
      headerName: 'Manual',
      widthMultiplier: 1.5,
      renderCell: (params) => {
        return (
          <CheckedIcon checked={params.value} />
        )
      },
    },
    {
      field: 'testing_mode',
      description: 'Testing Mode',
      headerName: 'Testing Mode',
      widthMultiplier: 2.0,
      renderCell: (params) => {
        return (
          <CheckedIcon checked={params.value} />
        )
      },
    },
  ].map(fullCol => {
    const { widthMultiplier, ...col } = fullCol;
    return {
      ...col,
      width: (fullCol.widthMultiplier || 1) * 50,
    }
  }), [lookup.values.site_hierarchy])

  React.useEffect(() => {
    queryHandler.values.site_uuid = searchParams.get("site_uuid") || ' '
    queryHandler.values.start_date = dateHelper.getStartDateFromUrlOrDefault(searchParams)
    queryHandler.values.end_date = dateHelper.getEndDateFromUrlOrDefault(searchParams)
    queryHandler.fetch().then(res => {
      setSortModel([{ field: 'usage_end_timestamp', sort: 'desc' }]);
    }, setSortModel);
    setPageState({ ...pageState, dense: true })
    return () => {
      setPageState({
        ...pageState,
        dense: false
      });
    };
  }, [])

  /* -------------------------------------------------------------------------- */
  /*                                 List Sorter                                */
  /* -------------------------------------------------------------------------- */

  const [sortModel, setSortModel] = React.useState([
    {
      field: 'usage_end_timestamp',
      sort: 'desc',
    },
  ]);

  /* -------------------------------------------------------------------------- */
  /*                                 List Filter                                */
  /* -------------------------------------------------------------------------- */

  const [startDateProps, setStartDateProps] = useState({
    minDate: undefined,
    maxDate: dateHelper.getToday(),
  });
  const [endDateProps, setEndDateProps] = useState({
    minDate: queryHandler.values.start_date || dateHelper.getToday(),
    maxDate: dateHelper.getBackDays(dateHelper.getToday(), -1),
  });

  React.useEffect(() => {
    setStartDateProps(prev => ({
      ...prev,
      maxDate: queryHandler.values.end_date || dateHelper.getToday(),
    }))
    setEndDateProps(prev => ({
      ...prev,
      minDate: queryHandler.values.start_date || dateHelper.getToday(),
    }))
    setSearchParams({
      ...searchParams,
      site_uuid: queryHandler.values?.site_uuid || "",
      start_date: queryHandler.values.start_date || dateHelper.getToday(),
      end_date: queryHandler.values.end_date || dateHelper.getToday()
    })
  }, [queryHandler.values.start_date, queryHandler.values.end_date, queryHandler.values.site_uuid])

  const refreshData = () => {
    // TODO: can this be done with useLocation() ??
    setTimeout(() => {
      navigateTo(routeConfig.usage_table_refresh)
    });
    setTimeout(() => {
      navigateTo(routeConfig.usage_table)
    });
  }

  return (
    <Page
      hideHeader
      fullWidth
    >

      {/* ----------------------------- Filter Section ---------------------------- */}
      <PageSection
        title={"Manage Usage Events"}
        direction="row"
        headerSx={{ mt: 1 }}
        onBack={() => navigateTo(routeConfig.dashboard)}
        onBackTooltip={"Back to Dashboard"}
        endActions={
          <Restricted to={[permissions.WRITE_USAGES]}>
            <MButton
              sx={{ mr: 1 }}
              startIcon={<AddCircleIcon />}
              variant="outlined"
              collapseXs
              onClick={() => navigateTo(routeConfig.usage_manual_entry)}
              children="Manual Entry"
            />
          </Restricted>
        }
      >
        <Grid container
          item
          spacing={5}
          sx={{ pr: 1 }}
          xs={12}
          md={6}
          lg={8}
        >
          {/* ------------------------------ Date Filter ------------------------------ */}
          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 1, lg: 6, }}
            title={"Date Range"}
          >
            <MDatePicker
              {...startDateProps}
              GridProps={{ lg: 6, xs: 6 }}
              value={queryHandler.values.start_date || ""}
              label={"Start Date"}
              labelId={`select-start-date`}
              onChange={queryHandler.createValueChangeHandler("start_date")}
            />
            <MDatePicker
              {...endDateProps}
              GridProps={{ lg: 6, xs: 6 }}
              value={queryHandler.values["end_date"] || ""}
              placeholder="End Date"
              label={"End Date"}
              labelId={`select-start-date`}
              onChange={queryHandler.createValueChangeHandler("end_date")}
            />
          </MFormGroup>

          <MFormGroup
            GridProps={{ item: true, xs: 12, md: 12, spacing: 1, lg: 6, }}
            title={"Site"}
          >
            <MAutoComplete
              GridProps={{
                xs: 12,
                lg: 12,
              }}
              options={options}
              value={queryHandler.values["site_uuid"]}
              onChange={queryHandler.createValueChangeHandler("site_uuid")}
              includeAll
              name="site_uuid"
            />
          </MFormGroup>

        </Grid>
        {/* ------------------------------ Action Items ------------------------------ */}

        <MFormGroup
          GridProps={{ xs: 12, md: 6, lg: 4, spacing: 1, }}
          labelSx={{ mb: 1 }}
          title={`Actions`}

        >

          <MButton
            GridProps={{ xs: 6, md: 8, }}
            sx={{ mt: 1, }}
            variant="contained"
            size={"large"}
            color="primary"
            onClick={queryHandler.fetch}
            children="Apply Filter"
            startIcon={<Search />}
            disabled={!queryHandler.isDirty}
          />
          <MButton
            GridProps={{
              xs: 6,
              md: 4,
            }}
            sx={{ mt: 0.6, }}
            size={"large"}
            color="primary"
            onClick={() => refreshData()}
            children="Refresh Data"
            startIcon={<RefreshIcon />}
            tooltip="Refresh the data"
          />
        </MFormGroup>
      </PageSection>
      <div style={{
        display: 'flex',
        width: "100%",
        overflowX: "auto",
        height: height - constants.APP_BAR_HEIGHT
      }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            loading={!!isLoading}
            rows={entities}
            columns={columns}
            columnBuffer={25}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onPageSizeChange={setPageSize}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            slots={{
              toolbar: CustomToolbar,
            }}
          />

        </div>
      </div>
    </Page >
  );
}