import { fetchWrapper } from '../helpers/fetchWrapper';

export const defaultBusinessUnit = {
  business_unit_name: "",
  business_unit_uuid: "",
  regional_company_uuid: "",
};

export const businessUnitService = {
  getAll,
  getById,
  create,
  update,
  delete: _delete
};

const baseUrl = `${process.env.REACT_APP_SERVER_URL}/api/business_unit`;

function getAll(token) {
  return fetchWrapper.get(token, baseUrl);
}

function getById(token, id) {
  return fetchWrapper.get(token, `${baseUrl}/${id}`);
}

function create(token, params) {
  return fetchWrapper.post(token, baseUrl, params);
}

function update(token, id, params) {
  return fetchWrapper.put(token, `${baseUrl}/${id}`, params);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(token, id) {

  return fetchWrapper.delete(token, `${baseUrl}/${id}`);
}