/**
 * ## Common Utilities
 * Wrapper for common utilities for arrays and objects.
 */
class Common {
    /**
     * Converts `this.value` to snake_case.
     */
    static toSnakeCase(str) {
        return str
            .replace(/([A-Z])/g, '_$1') // Convert camelCase and PascalCase to snake_case
            .replace(/[\s-]+/g, '_') // Convert spaces and hyphens to underscores
            .toLowerCase() // Convert the entire string to lowercase
            .replace(/^_/, ''); // Remove leading underscore if present
    }

    /**
     * Merge values from mergeObjs onto obj.
     * @param {*} obj 
     * @param  {...any} mergeObjs 
     * @returns 
     */
    static mergeObject(obj, ...mergeObjs) {
        if (typeof obj === "object") {
            mergeObjs.forEach(mergeObject => {
                if (typeof mergeObject === "object")
                    for (let key in mergeObject) {
                        (obj)[key] = mergeObject[key];
                    }
            })
        }
        return obj;
    }

    /**
     * Merge `arrB` into `arrA`. 
     * @param {T[]} arrA existing values
     * @param {T[]} arrB incoming values
     * @param {(a:T,b:T)=>bool} isMatch matching function to trigger element merge
     * @returns {T[]} new array composed of the union of arrA and arrB
     */
    static mergeArray(arrA, arrB, isMatch) {
        const result = arrA.slice();
        arrB.forEach(b => {
            const matchingIndex = result.findIndex(a => isMatch(a, b));
            if (matchingIndex > -1) {
                result[matchingIndex] = {
                    ...result[matchingIndex],
                    ...b
                };
            } else {
                result.push(b);
            }
        });
        return result;
    }

    /**
     * Log an event and its payload.
     * @param {string} event 
     * @param {string} payload 
     */
    static logEvent(event, payload) {
        console.log(`Event: ${Common.toSnakeCase(event)}:\n${Common.toReadable(payload, { nestedPrefix: ' > ' })}`);
        console.log();
    }


    /**
     * Extends `toString()` by adding whitespace (`\n` and `\t`) and formatting.
     * @param {Object} obj object to display
     * @param {Object} [opts] options for formatting
     * @param {string} [opts.nestedPrefix=''] Spacing after nested objects
     * @param {boolean} [opts.includeNewLine=true] Include new line characters
     * @returns {string} formatted string representation of the object
     */
    static toReadable(obj, opts = {}) {
        const { nestedPrefix = '', includeNewLine = true } = opts;

        if (nestedPrefix.length > 5) {
            return "\n< Depth Limit Reached >";
        }

        const newLineChar = includeNewLine ? `\n` : "";
        let readableString = '';
        const objKeys = Object.keys(obj || {});

        objKeys.forEach((key, index) => {
            let result = `${nestedPrefix}`;
            // const isObject = typeof obj[key] === "object";
            const hasToString = obj[key] && obj[key].toString;

            if (!hasToString) {
                const readableObject = Common.toReadable(obj[key], { nestedPrefix: `${nestedPrefix} `, includeNewLine });
                result = `${result}${key}:${newLineChar}${readableObject}`;
            } else {
                if (typeof obj[key] === "string" || typeof obj[key] === "number" || typeof obj[key] === "boolean" || obj[key] === null || obj[key] === undefined) {
                    result = `${result}${key}: ${obj[key]}`;
                } else {
                    result = `${result}${key}: ${obj[key]} [type ${typeof obj[key]}]`;
                }
            }
            if (index === objKeys.length - 1) {
                readableString = `${readableString}${result}`;
            } else {
                readableString = `${readableString}${result}${newLineChar}`;
            }
        });

        return readableString;
    }
}

export default Common;