import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Grid } from '@mui/material';



function MDateTimePicker(props) {
  const { GridProps, value, helperText, ...rest } = props;

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const content = (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        value={value === "" ? null : value}
        slotProps={{
          textField: {
            onKeyDown: onKeyDown,
            helperText: helperText,
            margin: "dense",
            size: props.size,
            sx: {
              bgcolor: "background.paper",
              borderRadius: 1,
            },
            fullWidth: props.fullWidth || !!GridProps,
          }
        }}
       
        {...rest}
        onError={(reason, value) => {
          if (!!reason)
            console.log(`Mui DateTimePicker - [Error:${reason}] - value:`, value);
        }}
      />
    </LocalizationProvider>
  );

  if (props.GridProps) {
    return <Grid
      item
      {...props.GridProps}
      children={content}
    />
  }
  return content
}

export default MDateTimePicker;