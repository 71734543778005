/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box, Typography, Divider, Container } from "@mui/material"
import { LayoutContext, } from '../../contexts';
import { PageContext, usePageContext } from '../../contexts/PageContext';
import { Errors } from '../containers';
import { MPortal } from '../@material-extend';
import { routeConfig } from '../../configs';
import { MIconButton } from '../@material-extend';
import PageStickyStepper from './PageStickyStepper';

const PagePropTypes = {
  collapsible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tabTitle: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hideTitle: PropTypes.bool,
  endActions: PropTypes.node,
  subtitleEndActions: PropTypes.node,
  fullWidth: PropTypes.bool,
  hideHeader: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  onBackTooltip: PropTypes.string,
  onBack: PropTypes.func,
  moreMenuOptions: PropTypes.shape({
    onClick: PropTypes.func,
    GridProps: PropTypes.object,
    data: PropTypes.object,
    vertical: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.object,
      icon: PropTypes.element,
      onClick: PropTypes.func,
    })),
  }),
  AppBar: PropTypes.shape({
    left: PropTypes.node,
    center: PropTypes.node,
    right: PropTypes.node,
  }),
  route: PropTypes.shape({
    id: PropTypes.string,
    path: PropTypes.string,
    fullPath: PropTypes.string,
    title: PropTypes.string,
  })
};
/**
* # Page Layout
* Renders the main content of the page, excluding drawers, dialogs, and app bars.
* @param {{
*   collapsible: boolean,
*   title: string | React.ReactNode,
*   tabTitle: string,
*   subtitle: string | React.ReactNode,
*   hideTitle: boolean,
*   fullWidth: boolean,
*   maxWidth: "xs" | "sm" | "md" | "lg" | "xl" | false,
*   titleVariant: string,
*   startActions: React.ReactNode,
*   endActions: React.ReactNode,
*   moreMenuOptions: {
*      label: string,
*      data: object,
*      icon: React.ReactNode,
*      onClick: () => void,
*   }[],
*   subtitleEndActions: React.ReactNode,
*   showPageNav: boolean,
*   children: React.ReactNode,
*   sx: object,
*   alignItems?: string,
*   onBack: () => void,
*   AppBar?: {
*     left?: React.ReactNode,
*     center?: React.ReactNode,
*     right?: React.ReactNode,
*   },
*   route?: {
*     id: string,    
*     path: string,
*     fullPath: string,    
*     title: string,
*   }
* }} props
* @returns 
*/
const _Page = (props) => {
  const { windowDimensions,
  } = useContext(LayoutContext);
  const { children, subtitle, AppBar, showPageNav, collapsible, tabTitle, title, subtitleEndActions, maxWidth, fullWidth, endActions, route = {}, onBack, hideHeader, ...other } = props
  const [browserTabTitle, setBrowserTabTitle] = useState(tabTitle);
  const pageContext = usePageContext();
  const showCollapsible = collapsible && (!showPageNav || windowDimensions.isMobile);

  useEffect(() => {
    if (!!tabTitle) {
      setBrowserTabTitle(tabTitle);
    } else {
      const newBrowserTabTitle = typeof title === "string" ? title : typeof route?.title === "string" ? route?.title : "NITROvision";
      setBrowserTabTitle(prevState => prevState !== newBrowserTabTitle ? newBrowserTabTitle : prevState);
    }
  }, [title, route?.title, tabTitle]);

  let pageTitle = title || route.title || "";
  let pageSubtitle = subtitle || !!pageTitle ? route.title : route.subtitle || "";

  if (!pageTitle) {
    pageTitle = pageSubtitle;
    pageSubtitle = !!pageTitle ? route.title : route.subtitle || "";
  }

  const headerContent = React.useMemo(() => (<>
    <Grid
      container
      direction="column"
      justifyContent="center"
      sx={{
        mt: windowDimensions.isMdUp ? 2 : 0
      }}
    >
      {/* --------------------------------- Page Actions -------------------------------- */}
      {!props.hideTitle && (pageSubtitle || pageTitle || endActions)
        && <ActionTextRow
          title={props.hideTitle ? undefined : pageTitle}
          sx={{ color: "text.primary" }}
          titleVariant={windowDimensions.isMobile ? "h2" : "h2"}
          endActions={endActions}
          startActions={<>
            {props.onBack ?
              <MIconButton
                size="small"
                disableRipple
                icon="back"
                tooltip={props.onBackTooltip}
                onClick={props.onBack}
              />
              : <></>
            }
            {props?.startActions}
          </>}
        />}

      {/* --------------------------------- Loading / Divider -------------------------------- */}
      {(pageSubtitle || subtitleEndActions) && <Divider sx={{ mb: 0 }} />}

      {/* --------------------------------- Title / Subtitle -------------------------------- */}
      {(pageSubtitle || subtitleEndActions) && <ActionTextRow
        title={subtitle}
        sx={{ color: "text.secondary", pl: 1 }}
        titleVariant="subtitle1"
        alignItems="flex-start"
        endActionsSx={showCollapsible ? { pr: 1 } : undefined}
        endActions={<>
          {props.subtitleEndActions}
          {showCollapsible && <MIconButton
            rotateDegrees={!pageContext.pageExpanded ? 0 : 180}
            onClick={evt => {
              evt.stopPropagation();
              pageContext.handleExpandPage()
            }}
            aria-expanded={pageContext.pageExpanded}
            aria-label="expand section"
            tooltip={pageContext.pageExpanded ? "Collapse all" : "Expand all"}
            GridProps={{ item: true }}
          >
            <ExpandMoreIcon fontSize='small' />
          </MIconButton>}
        </>
        }
      />}
    </Grid>

  </>), [pageSubtitle, subtitleEndActions, showCollapsible, pageContext.pageExpanded, props.onBack, props.onBackTooltip, props.startActions, props.hideTitle, props.endActions, props.subtitleEndActions, props.subtitle, props.title, props.titleVariant, props.startActions, props.endActions, props.subtitleEndActions, props.showPageNav, props.showPageNav, props.children, props.sx, props.alignItems, props.onBack, props.AppBar, props.route]);

  const content = React.useMemo(() => <Grid
    container
    direction={props.direction || "column"}
    alignItems={props.alignItems}
    justifyContent={props.justifyContent}
    gap={props.gap}
    spacing={props.spacing}
    sx={{
      mt: props.hideHeader ? 0 : windowDimensions.isMdUp ? 3 : 2
    }}
  >
    {children}
  </Grid>, [children, props.direction, props.alignItems, props.justifyContent, props.gap, props.spacing, props.hideHeader]);

  return (<PageContext.Provider value={pageContext}>
    <span id="app-start" />
    <Box {...other} sx={{ width: "100%", backgroundColor: '#fff', position: "relative" }}>
      {AppBar
        && <>
          {AppBar.left && <MPortal portalId={routeConfig.portal_navbar_left.id} children={AppBar.left} />}
          {AppBar.center && <MPortal portalId={routeConfig.portal_navbar_center.id} children={AppBar.center} />}
          {AppBar.right && <MPortal portalId={routeConfig.portal_navbar_right.id} children={AppBar.right} />}
        </>}
      <Helmet>
        <title>{browserTabTitle}</title>
      </Helmet>
      {props.fullWidth
        ? <>
          {!props.hideHeader && headerContent}
          <Errors />
          {content}
        </>
        : <Container maxWidth={Object.keys(props).includes("maxWidth") ? props.maxWidth : windowDimensions.isMdUp ? "md" : "sm"}>
          <Grid container direction="column" sx={{

            pl: windowDimensions.isXl ? 9
              : windowDimensions.isLg ? 0
                : windowDimensions.isMd ? 0
                  : windowDimensions.isXs ? 0
                    : 1,
            pr: windowDimensions.isXl ? 9
              : windowDimensions.isLg ? 22
                : windowDimensions.isMd ? 18
                  : windowDimensions.isXs ? 0
                    : 1,
          }}>
            {!props.hideHeader && headerContent}
            <Errors />
            {showPageNav && <PageStickyStepper pageContext={pageContext} />}

            {content}
          </Grid>
        </Container>
      }
    </Box>
  </PageContext.Provider>
  )
}

const Page = React.memo(_Page);
Page.propTypes = PagePropTypes;


ActionTextRow.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleVariant: PropTypes.string,
  alignItems: PropTypes.string,
  startActions: PropTypes.node,
  endActions: PropTypes.node,
  endActionsSx: PropTypes.object,
  sx: PropTypes.object,
}

function ActionTextRow(props) {
  if (typeof props.title === "object") {
  }

  return <Grid container direction="row" sx={{ py: 0.5 }} alignItems={props.alignItems || "center"}>
    {props.startActions && <Grid item children={props.startActions} />}
    {props.title && typeof props.title === "string" ? <Typography variant={props.titleVariant} sx={props.sx} children={props.title} /> : props.title}
    {props.endActions && <Grid item xs container justifyContent="flex-end" sx={{ mb: 1, pr: 1.5, ...props.endActionsSx }} children={props.endActions} />}
  </Grid>
}

export default Page;

