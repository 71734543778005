const permissions = {
  WRITE_SITE_GENERAL: "write:site_general",
  WRITE_SITE_STATUS: "write:site_status",
  WRITE_TANK_DETAILS: "write:tank_details",
  WRITE_PLANT_DETAILS: "write:plant_details",
  WRITE_REPRESENTATIVE_SALES: "write:representatives_sales",
  WRITE_REPRESENTATIVE_OPS: "write:representatives_ops",
  WRITE_SITE_CONTACTS: "write:site_contacts",
  WRITE_SUPPLIER_CONTACTS: "write:supplier_contacts",
  WRITE_SITE_HIERARCHY: "write:site_hierarchy",
  WRITE_CUSTOMER_CONTRACTS: "write:customer_contracts",
  READ_CUSTOMER_CONTRACTS: "read:customer_contracts",
  WRITE_SUPPLIER_CONTRACTS: "write:supplier_contracts",
  READ_SUPPLIER_CONTRACTS: "read:supplier_contracts",
  WRITE_SUPPLIER_DETAILS: "write:supplier_details",
  WRITE_SITE_TANK_NITROFILL: "write:site_tank_nitrofill",
  WRITE_SITE_ADDRESS: "write:site_address",
  WRITE_TANK_ACCESS: "write:tank_access",
  WRITE_SUPPLIER_CONTRACT_ACCOUNT_NUMBER: "write:supplier_contract_account_number",
  READ_CUSTOMER_CONTRACT_RATES: "read:customer_contract_rates",
  WRITE_CUSTOMER_CONTRACT_RATES: "write:customer_contract_rates",
  READ_CUSTOMER_CONTRACT_MINIMUMS: "read:customer_contract_minimums",
  WRITE_CUSTOMER_CONTRACT_MINIMUMS: "write:customer_contract_minimums",
  READ_CUSTOMER_CONTRACT_PRICING_FEES: "read:customer_contract_pricing_fees",
  WRITE_CUSTOMER_CONTRACT_PRICING_FEES: "write:customer_contract_pricing_fees",
  READ_SUPPLIER_CONTRACT_RATES: "read:supplier_contract_rates",
  WRITE_SUPPLIER_CONTACT_RATES: "write:supplier_contract_rates",
  DELETE_SUPPLIER_CONTACT_RATES: "delete:supplier_contract_rates",
  READ_SUPPLIER_CONTRACT_TAKE_OR_PAY_TERMS: "read:supplier_contract_take_or_pay_terms",
  WRITE_SUPPLIER_CONTRACT_TAKE_OR_PAY_TERMS: "write:supplier_contract_take_or_pay_terms",
  WRITE_TANK_FILLS: "write:tank_fills",
  WRITE_TANK_FILL_QUANTITY_REVIEW: "write:tank_fill_quantity_review",
  DELETE_TANK_FILLS: "delete:tank_fills",
  DELETE_CUSTOMER_CONTRACTS: "delete:customer_contracts",
  DELETE_SUPPLIER_CONTRACTS: "delete:supplier_contracts",
  WRITE_BILLING_INFORMATION: "write:billing_information",
  READ_USAGES: "read:usages",
  WRITE_USAGES: "write:usages",
  READ_NITROCRETE_REPRESENTATIVES: "read:nitrocrete_representatives",
  WRITE_NITROCRETE_REPRESENTATIVES: "write:nitrocrete_representatives",
  DELETE_USAGES: "delete:usages",
  DELETE_DELIVERY_TICKETS: "delete:delivery_tickets",
  VIEW_SITE_GENERAL: "view:site_general",
  VIEW_SITE_CONTACTS: "view:site_contacts",
  VIEW_SITE_TANK_DETAILS: "view:site_tank_details",
  VIEW_SITE_PLANT_DETAILS: "view:site_plant_details",
  VIEW_SITE_TANK_ACCESS: "view:site_tank_access",
  VIEW_SITE_SUPPLIER_CONTRACT: "view:site_supplier_contract",
  VIEW_SITE_CUSTOMER_CONTRACT: "view:site_customer_contract",
  VIEW_SITE_BILLING_INFORMATION: "view:site_billing_information",
  VIEW_SITE_REPRESENTATIVES: "view:site_representatives",
  VIEW_TOOL_SUPPLIERS: "view:tool_suppliers",
  VIEW_TOOL_TANK_FILLS: "view:tool_tank_fills",
  VIEW_TOOL_OPERATIONS_COMMENT: "view:tool_operations_comment",
  VIEW_TOOL_USAGE_COMMENT: "view:tool_usage_comment",
  VIEW_TOOL_NITROVISION: "view:tool_nitrovision",
  VIEW_TOOL_JIRA: "view:tool_jira",
  VIEW_TOOL_TABLEAU: "view:tool_tableau",
  VIEW_TOOL_SITE_MANAGER: "view:tool_site_manager",
  VIEW_TOOL_DELIVERY_TICKETS: "view:tool_delivery_tickets",
  VIEW_TOOL_USAGES: "view:tool_usages",
  VIEW_TOOL_NITROCRETE_REPRESENTATIVES: "view:tool_nitrocrete_representatives"

};

const roles = {
  COMMERCIAL_CUSTOMER_ADMIN: "Commercial Customer Admin",
  EDITOR: "EDITOR",
  GUEST: "GUEST"
};

export { permissions, roles };