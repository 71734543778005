import React, { useContext } from 'react';
import { Button, Grid, ToggleButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { LayoutContext } from '../../contexts';
import BackIcon from '@mui/icons-material/NavigateBefore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import MIconButton from './MIconButton';
MButton.propTypes = {
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    /** If true, only show icon on xs */
    collapseXs: PropTypes.bool,
    GridProps: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onToggle: PropTypes.func,
    color: PropTypes.string,
    toggled: PropTypes.bool,
}

function MButton(props) {
    const { windowDimensions } = useContext(LayoutContext);
    const { tooltip, GridProps, startIcon, sx, fullWidth, color, collapseXs, icon, ...other } = props;

    let iconChild = startIcon || icon;

    if (!!icon && !startIcon) {
        switch (icon) {
            case "back": {
                iconChild = <BackIcon sx={{ height: 16, width: 16 }} fontSize="inherit" />;
                break;
            }
            case "add": {
                iconChild = <AddCircleIcon sx={{ height: 16, width: 16 }} fontSize="inherit" />;
                break;
            }
            case "delete": {
                iconChild = <DeleteIcon sx={{ height: 16, width: 16 }} fontSize="inherit" />;
                break;
            }
            case "edit": {
                iconChild = <EditIcon sx={{ height: 16, width: 16 }} fontSize="inherit" />;
                break;
            }
            case "expand": {
                iconChild = <ExpandMoreIcon sx={{ height: 16, width: 16 }} fontSize="inherit" />;
                break;
            }
            case "up": {
                iconChild = <ArrowCircleUpIcon sx={{ height: 16, width: 16 }} fontSize="inherit" />;
                break;
            }
            default: {
                break;
            }
        }
    }

    const isToggleButton = !!props.toggled || !!props.onToggle;
    const onlyShowIcon = !windowDimensions.isSmUp && collapseXs;
    let isColorOverride = false;

    const handleToggle = (event) => {

        if (props.onToggle) {
            props.onToggle(event);
        }
        if (props.onClick) {
            props.onClick(event);
        }
    }
    let buttonSx = {
        width: GridProps && 1,
        px: 2,
        py: 1,
        ...sx
    }

    if (color === "text.primary") {
        buttonSx.color = "text.primary";
        buttonSx.borderColor = "text.primary";
        isColorOverride = true;
    }

    if (color === "text.secondary") {
        buttonSx.color = "text.secondary";
        buttonSx.borderColor = "text.secondary";
        isColorOverride = true;
    }
    if (onlyShowIcon && !isToggleButton) {
        return <MIconButton
            {...other}
            GridProps={GridProps}
            tooltip={tooltip}
            color={isColorOverride ? "inherit" : color}
            onChange={handleToggle}
            children={iconChild}
        />
    }

    let result = isToggleButton
        ? <ToggleButton {...other}
            sx={buttonSx}
            selected={props.toggled}
            color={isColorOverride ? "inherit" : color}
            onChange={handleToggle}
            startIcon={iconChild}
            children={other.children} />
        : <Button
            {...other}
            sx={buttonSx}
            color={isColorOverride ? "inherit" : color}
            startIcon={iconChild}
            children={other.children} />

    if ((tooltip || !props.disabled) && (collapseXs && !windowDimensions.isMdUp && typeof props.children === "string")) {

        let tooltipProps = !tooltip || typeof tooltip === 'string' ?
            {
                placement: "top",
                title: tooltip || props.children
            } :
            {
                placement: "top",
                ...tooltip,
                title: props.tooltip.title || props.children
            };

        if (tooltipProps.tooltip) {
            delete tooltipProps.tooltip;
        }
        result = <Tooltip {...tooltipProps} children={result} />
    }

    if (GridProps) {
        result = <Grid item {...GridProps} children={result} />;
    }

    return result;

};




export default MButton