import { fetchWrapper } from '../helpers/fetchWrapper';



export const lookupService =  {
  getLookupValues,
};

const baseUrl = `${process.env.REACT_APP_SERVER_URL}/api`;

function getLookupValues(token, lookupType) {
  return fetchWrapper.get(token, `${baseUrl}/${lookupType}`);
}
