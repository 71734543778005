/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { entityConfig } from '../configs';
import { createDefaultQueryHandler, dateHelper, useQueryHandler } from '.';
import { createEntityService } from './createEntityService';
import { LayoutContext } from '../contexts/LayoutContext';
import { useAuth0 } from '@auth0/auth0-react';
import Common from './common';

/**
 * ## createEntityContext
 * Creates a new entity context to encapsulate an active entity and entity list state with integrated CRUD operations.
 * - CRUD operations: create, read, update, delete.
 * - Query operations: read based on `QueryOpts` passed in.
 * - Active entity state: the entity that is currently being displayed/edited.
 * - Entity list state: list of cached entities.
 * @template T
 * @template {{}} QueryValues
 * @template {Record<keyof QueryValues, {defaultValue: string, options?: string[]}>} QueryOpts
 * @template {{entities: T[]; activeEntity: T; queryValues: Record<keyof QueryOpts, any>;}} ContextApi
 * @param  {{
 * entityType: keyof typeof entityConfig["type"];
 * defaultEntity: T;
 * queryOpts?: QueryOpts;
 * queryValues?: QueryValues;
 * filterEntity?: (entity: T, api: ContextApi) => boolean;
 * mapQueryResults: (results: T[]) => T[];
 * upsertMode: "create" | "update";
 * }} options
 * @returns {{
 * defaultEntity: T;
 * Provider: React.ComponentType;
 * useContext: () =>  {
 *   defaultValues: T;
 *   isLoading: boolean;
 *   upsertEntity: (updatedEntity: Partial<T>, mode: "create" | "update") => Promise<string | T>;
 *   setActiveEntity: (entityUuid: string) => void;
 *   activeEntity: T;
 *   queryHandler: {
 *       values: QueryValues;
 *       options: Record<keyof QueryValues, any[]>;
 *       createValueChangeHandler: <K extends keyof QueryValues>(key: K) => (value: QueryValues[K]) => void;
 *       createEventChangeHandler: <K extends keyof QueryValues>(key: K) => (event: any) => void;
 *       setParamOptions: (key: keyof QueryValues, options: any[]) => void;
 *       isDirty: boolean;
 *       reset: () => void;
 *       fetch: (endpointPath?: string)  => Promise<any>;
 *   };
 *   deleteEntity: (entityUuid: string) => Promise<T>;
 *   bulkUpdate: {
 *       reset: () => void;
 *       setSelectedIds: (ids: string[]) => void;
 *       selectedIds: string[];
 *       startUpdate: () => void;
 *       progress: number;
 *       totalCount: number;
 *       completedUpdates: T[];
 *       pendingUpdates: T[];
 *       status: "idle" | "ready" | "updating" | "done";
 *   };
 * };
 * Context: React.Context<{
 *   isLoading: boolean;
 *   deleteEntity: (entityUuid: string) => Promise<T>;
 *   upsertEntity: (updatedEntity: Partial<T>, mode: "create" | "update") => Promise<string | T>;
 *   setActiveEntity: (entityUuid: string) => void;
 *   activeEntity: T;
 *   defaultValues: T;
 *   queryHandler: {
 *       values: QueryValues;
 *       options: Record<keyof QueryValues, any[]>;
 *       createValueChangeHandler: <K extends keyof QueryValues>(key: K) => (value: QueryValues[K]) => void;
 *       createEventChangeHandler: <K extends keyof QueryValues>(key: K) => (event: any) => void;
 *       setParamOptions: (key: keyof QueryValues, options: any[]) => void;
 *       isDirty: boolean;
 *       reset: () => void;
 *       fetch: (endpointPath?: string) => void;
 *   };
 *   includeQueryParams: boolean;
 *   bulkUpdate: {
 *       reset: () => void;
 *       setSelectedIds: (ids: string[]) => void;
 *       selectedIds: string[];
 *       startUpdate: () => void;
 *       progress: number;
 *       totalCount: number;
 *       completedUpdates: T[];
 *       pendingUpdates: T[];
 *       status: "idle" | "ready" | "updating" | "done";
 *   };
 *  }>
 * }}
 */
const createEntityContext = (options) => {
    const { entityType, defaultEntity, queryOpts, upsertMode, includeQueryParams = false, mapQueryResults, filterEntity } = options;
    const isEntityConfigValid = entityConfig[entityType] !== undefined;
    if (isEntityConfigValid) {

        // throw new Error(`Invalid entity type: ${entityType}`);
    }

    const entityDisplayName = entityConfig.getDisplayName(entityType);
    const service = createEntityService(entityType);

    const defaultContextValue = {
        isLoading: false,
        deleteEntity: async (entityUuid) => { return entityUuid; },
        upsertEntity: (updatedEntity) => new Promise()
            .then(() => { alert("Context not instantiated"); return defaultEntity; })
            .catch(() => "Not implemented"),
        setActiveEntity: (entityUuid) => { },
        activeEntity: defaultEntity,
        queryHandler: createDefaultQueryHandler(queryOpts || {}),
        bulkUpdate: {
            reset: () => { },
            setSelectedIds: (ids) => { },
            selectedIds: [],
            startUpdate: () => { },
            progress: 0,
            totalCount: 0,
            completedUpdates: [],
            pendingUpdates: [],
            status: "idle",
        },
    }

    const EntityContext = React.createContext(defaultContextValue);

    /**
     * Manages entity state.
     * @param {*} props 
     * @returns 
     */
    const EntityContextProvider = (props) => {
        const { isLoading, handleSuccess, handleError } = useContext(LayoutContext);
        const { getAccessTokenSilently } = useAuth0();

        /* -------------------------------------------------------------------------- */
        /*                                  List State                                */
        /* -------------------------------------------------------------------------- */
        const [entities, setEntities] = useState([]);
        const [activeEntityUuid, setActiveEntityUuid] = useState(null);
        const [filteredEntities, setFilteredEntities] = useState([]);
        /**
         * Sets entity state.
         * @param {T[]} entities 
         */
        const setEntityState = (incomingEntities) => {
            handleSuccess();
            const newEntities = incomingEntities.map(e => ({ ...e, id: entityConfig.getUuid(e) }));
            setEntities(newEntities);
        }

        /**
         * If entity exists in entities state, edit it. Otherwise, add it.
         * @param {T} entity 
         */ 
        const updateOrAddEntity = (entity) => {
            const entityUuid = entityConfig.getUuid(entity);
            const newEntities = entities.slice();
            const targetIndex = newEntities.findIndex(e => entityConfig.getUuid(e) === entityUuid);
            if (targetIndex !== -1) {
                newEntities[targetIndex] = {
                    ...newEntities[targetIndex],
                    ...entity
                };
            } else {
                newEntities.push({
                    id: entityUuid,
                    ...entity,
                });
            }
            setEntities(newEntities);
        }
        /**
         * Remove entity from entities state.
         * @param {string} entityId 
         */
        const removeEntity = (entityId) => {
            const newEntities = entities.slice();
            const entityToDelete = newEntities.find(e => entityConfig.getUuid(e) === entityId);
            setEntities(newEntities.filter(e => entityConfig.getUuid(e) !== entityId));
            return entityToDelete;
        }


        /* -------------------------------------------------------------------------- */
        /*                                Query Handler                               */
        /* -------------------------------------------------------------------------- */
        const queryHandler = useQueryHandler({
            makeQuery: service.getAll,
            onSuccess: response => {
                const newEntities = !!mapQueryResults ? mapQueryResults(response[entityType]) : response[entityType];
                setEntityState(newEntities);
            },
            onError: (err) => handleError(`Unable to get ${entityDisplayName} records`, err),
            prepareQueryParam: (queryParam, value) => {
                Common.logEvent("prepareQueryParam", {queryParam, value});
                switch (queryParam) {
                    case "start_date":
                    case "end_date":
                        return dateHelper.formatDate(value);
                    case "review_only":
                        const review_only = value ? (value.target ? (value.target.checked ? value.target.checked : false) : false) : value
                        return review_only;
                    case "include_inactive":
                          const include_inactive = value ? (value.target ? (value.target.checked ? value.target.checked : false) : false) : value
                          return include_inactive;    
                    default: return value;
                }
            },
            initialValues: includeQueryParams ? {
                start_date: dateHelper.getBackDays(new Date(), 2),
                end_date: dateHelper.getBackDays(dateHelper.getToday(), -1),
                site_uuid: ' ',
                review_only: false,
                include_inactive: false
            } : {}
        });


        /* -------------------------------------------------------------------------- */
        /*                                 Entity CRUD                                */
        /* -------------------------------------------------------------------------- */
        /**
         * Creates or updates an entity based on if the entity has an id.
         * @param {T} entity 
         */
        const upsertEntity = (entity, mode) => {

            for (var key in entity) {
                if (entity[key] === "" || entity[key] === undefined) {
                    entity[key] = null;
                }
            }
            const isUpdate = upsertMode !== "create" && (entityConfig.getUuid(entity) || mode === "update" || upsertMode === "update");
            if (isUpdate) {
                return updateEntity(entity);
            } else {
                return createEntity(entity);
            }
        }

        /**
         * Creates a new entity .
         * @param {T} data 
         */
        const createEntity = async (formData) => {
            // Remove null values from create payload
            for (var key in formData) {
                if (!formData[key]) {
                    delete formData[key];
                }
            }
            const token = await getAccessTokenSilently();
            return await service.create(token, formData)
                .then((res) => {
                    console.log("Created entity", res);
                    // TODO - make more effecient
                    const newEntity = res[entityType];
                    newEntity.id = newEntity[`${entityType}_uuid`];
                    if (!newEntity.id)
                        newEntity.id = newEntity[`${entityType}`];
                    updateOrAddEntity(newEntity);
                    if (!props.omitSnackbar)
                        handleSuccess(`Successfully created ${entityDisplayName}`);
                    return newEntity;
                })
                .catch((err) => {
                    if (!props.omitSnackbar)
                        handleError(`CEC - Unable to create ${entityDisplayName}`, err);
                    return false;
                })
        }
        /**
         * Deletes an entity.
         * @param {T} data 
         */
        const deleteEntity = async (entityId) => {
            const token = await getAccessTokenSilently();
            return await service.delete(token, entityId)
                .then((res) => {
                    console.log("Updated Entity", res);

                    // TODO - make more effecient
                    const removedEntity = removeEntity(entityId);
                    if (!props.omitSnackbar)
                        handleSuccess(`Successfully deleted ${entityDisplayName}`);
                    return removedEntity;
                })
                .catch((err) => {
                    if (!props.omitSnackbar)
                        handleError(`Unable to delete ${entityDisplayName}`, err);
                    return false;
                })
        }

        /**
         * Updates an entity.
         * @param {string} uuid 
         * @param {T} formData 
         */
        const updateEntity = async (formData) => {
            const uuid = entityConfig.removeUuid(formData);
            const token = await getAccessTokenSilently();
            return service.update(token, uuid, formData)
                .then((res) => {
                    const newEntity = res[entityType];
                    newEntity.id = newEntity[`${entityType}_uuid`];
                    updateOrAddEntity(formData);
                    if (!props.omitSnackbar)
                        handleSuccess(`Successfully updated ${entityDisplayName}`);
                    return newEntity;
                })
                .catch((err) => {
                    if (!props.omitSnackbar)
                        handleError(`Unable to update ${entityDisplayName}`, err);
                    return false;
                })
        }

        /**
         * Get's the entity by uuid and sets it to active entity.
         * @param {string} uuid 
         */
        const setActiveEntity = async (uuid) => {
            setActiveEntityUuid(uuid);
            if (!!uuid) {
                const token = await getAccessTokenSilently();
                service.getById(token, uuid)
                    .then((res) => {
                        updateOrAddEntity(res[entityType]);
                    })
                    .catch((err) => handleError(`Unable to load ${entityConfig.getDisplayName(entityType)}`, err))
            }
        }

        /* -------------------------------------------------------------------------- */
        /*                                 Bulk Update                                */
        /* -------------------------------------------------------------------------- */
        /* ---------------------------- Bulk Update State --------------------------- */
        const [selectedIds, setSelectedIds] = useState([]);
        const [pendingUpdates, setPendingUpdates] = useState([]);
        const [completeUpdates, setCompleteUpdates] = useState([]);
        const [bulkTotalCount, setBulkTotalCount] = useState(0);
        const [bulkUpdateStatus, setBulkUpdateStatus] = useState("idle");


        const handleResetBulkUpdate = () => {
            setCompleteUpdates([]);
            setSelectedIds([]);
            setBulkUpdateStatus("idle");
        }

        /**
         * 
         * @param {T[]} entities 
         */
        const handleSetBulkUpdate = (ids) => {
            const bulkUpdateEntities = entities.filter(e => ids.includes(entityConfig.getUuid(e)));
            setSelectedIds(ids)
            setPendingUpdates(bulkUpdateEntities);
            setCompleteUpdates([]);
            setBulkTotalCount(ids?.length);
            setBulkUpdateStatus(ids?.length > 0 ? "ready" : "idle");
        }

        const bulkUpdate = () => {
            if (pendingUpdates.length === 0) {
                throw new Error("No EDIs selected for bulk update");
            }
            setCompleteUpdates([]);
            setBulkUpdateStatus("updating");
            bulkUdpateNextAsync(pendingUpdates.slice());
        }
        /**
         * 
         * @param {T[]} entities 
         */
        const bulkUdpateNextAsync = async (entities) => {
            if (entities.length > 0) {
                const next = entities.shift();
                await updateEntity(next).then((res) => {
                    setCompleteUpdates(prevState => ([...prevState, next]));
                });
                bulkUdpateNextAsync(entities);
            } else {
                setBulkUpdateStatus("done");
                queryHandler.fetch();
            }
        }

        /* -------------------------------------------------------------------------- */
        /*                                Side Effects                                */
        /* -------------------------------------------------------------------------- */
        useEffect(() => {
            const activeEntity = entities.find(e => entityConfig.getUuid(e) === activeEntityUuid);
            if (filterEntity) {
                let newEntities = entities.slice().filter(ent => filterEntity(ent, {
                    entities: entities,
                    activeEntity: activeEntity,
                    queryValues: queryHandler.values,
                }));
                setFilteredEntities(prevState => prevState !== newEntities ? newEntities : prevState);
            }

            else {
                setFilteredEntities(entities);
            }


        }, [entities, queryHandler.values])


        return <EntityContext.Provider
            value={{
                isLoading,
                upsertEntity: upsertEntity,
                updateEntity: updateEntity,
                createEntity: createEntity,
                activeEntity: entities.find(e => entityConfig.getUuid(e) === activeEntityUuid) || defaultEntity,
                setActiveEntity,
                entities: filteredEntities,
                queryHandler,
                deleteEntity,
                defaultValues: defaultEntity,
                bulkUpdate: {
                    reset: handleResetBulkUpdate,
                    setSelectedIds: handleSetBulkUpdate,
                    startUpdate: bulkUpdate,
                    totalCount: bulkTotalCount,
                    selectedIds: selectedIds,
                    status: bulkUpdateStatus,
                    completedUpdates: completeUpdates,
                    pendingUpdates: pendingUpdates,
                },

            }}
        >
            {props.children}
        </EntityContext.Provider>
    }


    return {
        defaultEntity,
        Context: EntityContext,
        Provider: EntityContextProvider,
        useContext: () => useContext(EntityContext),
    }

}


export default createEntityContext;    